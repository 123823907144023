export default {
    nav: {
        data: {
            label: 'Data configuration'
        },
        masterdata: {
            truckingCompany: 'Transport Company',
            label: 'Assets',
            users: 'Users',
            truck: 'Trucks',
            trailer: 'Trailers',
            globalValues: 'Settings',
            globalFiles: 'Special files',
            trip: 'Trips',
            customer: 'Customers',
            invoice: 'Invoices',
            district: 'Districts',
            service: 'Service truck/trailer',
            emailTemplate: 'Email template',
            activityStatusEmailTemplate: 'Activity template',
            driver: 'Drivers',
            metabase: 'Metabase',
            tasks: 'Tasks',
            goodsDescription: 'Goods description',
            costCalculation: 'Cost calculation',
        },
    },
    general: {
        notAvailable: 'Not available',
        noExtraInformationAvailable: 'No extra information available',
        at: 'at',
        viewLogs: 'View logs',
        error: 'Error occurred',
        loading: 'Loading',
        canceled: 'Canceled',
        loadTrips: 'Load Trips',
        book: 'Book!',
        na: 'n/a',
        somethingWentWrong: 'Something went wrong.',
        exportInProgress: 'Export started. Large exports will take long time to finish (Limit 10000). Please wait... ',
    },
    geo: {
        notFound: 'No location found!',
        notStarted: 'Geo Location can\'t be found',
    },
    enums: {
        weekday: {
            monday: 'Monday',
            tuesday: 'Tuesday',
            wednesday: 'Wednesday',
            thursday: 'Thursday',
            friday: 'Friday',
            saturday: 'Saturday',
            sunday: 'Sunday',
        },
        lengthUnit: {
            miles: 'Miles',
            milesShort: 'mi',
            kilometers: 'Kilometers',
            kilometersShort: 'km',
        },
        externalDataSource: {
            transics: 'Transics',
        },
        costCalculationVariation: {
            exchange_rate: 'Exchange Rate',
            europe_mainland: 'Europe Mainland',
            north_uk: 'North UK',
            south_uk: 'South UK',
            ireland: 'Ireland',
        },
        correctionType: {
            daily_rate: 'Daily Rate',
            other_costs: 'Other Costs',
        },
    },
    tooltips: {
        masquerade: 'Masquerade',
        edit: 'Edit',
        copy: 'Copy',
        editAllocations: 'Edit allocations',
        delete: 'Delete',
        add: 'Add',
        view: 'View',
        restore: 'Restore',
    },
    form: {
        fileType: {
            pdf: 'PDF',
            image: 'IMG',
            xls: 'XLS',
            any: 'Other',
            none: 'No file uploaded yet',
        },
        pdfLoading: 'Loading pdf...',
        pdfError: 'Error loading pdf...',
        sendButton: 'Send',
        saveButton: 'Save',
        saveCreateEmpty: 'Save & New',
        saveAndBack: 'Save & Back',
        saveAndCopy: 'Save & Copy',
        previewSavePdf: 'Save & Preview PDF',
        sendDraftSaveButton: 'Save & Send',
        saveClose: 'Save & Close',
        close: 'Close',
        cancel: 'Cancel',
        continue: 'Continue',
        repeat: 'Repeat',
        multiPick: {
            searchPlaceholder: 'Select…',
            selectedText: '$1 of $2 selected',
            noneSelectedText: 'None selected',
            selectAllButton: 'All',
            selectNoneButton: 'None',
        },
        notifications: {
            saveSuccess: 'Saved successfully',
            deleteSuccess: 'Deleted successfully',
            saveError: 'Error with saving ({{status}})',
            saveValError: 'Not all information is filled in correctly',
            saveAuthError: 'Invalid credentials',
            newAppVersion: 'New version available, click to reload page as soon as possible',
        },
        submitButton: 'Submit',
        cancelButton: 'Cancel',
        addButton: 'Add',
        copyButton: 'Copy',
        publishButton: 'Publish',
        startDate: 'Start Date',
        endDate: 'End Date',
        deleteConfirmation: 'Are you sure you want to delete this entity?',
        restoreConfirmation: 'Are you sure you want to restore this entity?',
        clearConfirmation: 'Are you sure you wish to clear this entity details?',
        goBackConfirmation: 'Are you sure you want to go back without saving?',
        search: 'Search',
        actions: 'Actions',
        exportButton: 'Export'
    },
    trailer: {
        create: {
            title: 'Add trailer'
        },
        overview: {
            title: 'Trailer overview',
            addButton: 'Add trailer',
        },
        edit: {
            title: 'Edit trailer {{id}}',
            basicInfoTitle: 'Basic info',
            trailerSpecTitle: 'Trailer specs',
            apkTitle: 'TUV',
            activateTrailer: 'Activate trailer',
            deactivateTrailer: 'Deactivate trailer',
            cannotDeactivate: 'Cannot deactivate. Please send trailer to yard first',
            trailerDeactivated: 'The trailer is deactivated',
            locationRequired: 'Fill the yard location which is required',
            dropTruck: 'Drop the truck to deactivate trailer',
            errorMessage: {
                fleetNumber: 'Fleet number must be unique among active trailers.'
            }
        },
        field: {
            attachedTruck: {
                label: 'Attached truck',
            },
            id: {
                label: 'Id',
            },
            licensePlate: {
                label: 'License plate',
            },
            apkExpiryDate: {
                label: 'APK expiry date'
            },
            apkExpiryDateFrom: {
                label: 'APK expiry date from'
            },
            apkLastExecutedDate: {
                label: 'APK last executed date'
            },
            apkExecutedDate: {
                label: 'APK executed date'
            },
            apkExpiryDateNew: {
                label: 'APK expiry date new'
            },
            fleetNumber: {
                label: 'Fleet number'
            },
            fleetCode: {
                label: 'Fleet code'
            },
            service: {
                label: 'Service'
            },
            tuv: {
                label: 'Tuv'
            },
            owner: {
                label: 'Owner'
            },
            truckingCompany: {
                label: 'Owner company'
            },
            dataSource: {
                label: 'Data provider',
            },
            dataReference: {
                label: 'Transics key',
            },
            location: {
                label: 'Yard location',
            },
            source: {
                label: 'source'
            },
            equipment: {
                label: 'Equipment'
            },
            remarks: {
                label: 'Remarks'
            },
            active: {
                label: 'Active / Inactive trailer',
                overviewColumn: 'Active',
                options: {
                    all: 'All',
                    yes: 'Active',
                    no: 'Inactive',
                }
            },
            assignedUser: {
                label: 'Assigned to'
            },
            name: {
                label: 'Name'
            },
            cooled: {
                label: 'Cooled / Not cooled',
                overviewLabel: 'Cooled',
                options: {
                    all: 'All',
                    yes: 'Cooled',
                    no: 'Not cooled',
                },
            },
            lift: {
                label: 'Lift / No lift',
                overviewLabel: 'Lift',
                options: {
                    all: 'All',
                    yes: 'Lift',
                    no: 'No lift',
                },
            },
            taut: {
                label: 'Taut',
                options: {
                    all: 'All',
                    yes: 'Taut',
                    no: 'Not taut',
                },
            },
            frigo: {
                label: 'Frigo',
                options: {
                    all: 'All',
                    yes: 'Frigo',
                    no: 'Not frigo',
                },
            },
            box: {
                label: 'Box',
                options: {
                    all: 'All',
                    yes: 'Box',
                    no: 'Not box',
                },
            },
            adr: {
                label: 'ADR',
                options: {
                    all: 'All',
                    yes: 'ADR',
                    no: 'Not ADR',
                },
            },
            lastKnownLocation: {
                label: 'Last known location',
                updatedAtLabel: 'Last updated at'
            }
        },
    },
    truck: {
        overview: {
            title: 'Truck overview',
            addButton: 'Add truck',
        },
        modal: {
            headerTitle: 'Fill the location to activated truck',
            headerTitleSplitTrip: 'Fill the location to yard drop - pick up',
            changeActivityType: 'Change type Activity to yard drop',
            detachTrailerFromTruck: 'Detach trailer from the truck first',
            changeModalEta: 'Change manual ETA',
        },
        edit: {
            title: 'Edit truck {{id}}',
            basicInfoTitle: 'Basic info',
            permitInfoTitle: 'Permit info',
            identificationTitle: 'Truck identification',
            specsTitle: 'Truck specs',
            tuvTitle: 'Truck TUV',
            listServices: 'List services',
            activateTruck: {
                label: 'Activate truck',
                success: 'Truck successfully activated',
            },
            deactivateTruck: {
                label: 'Deactivate truck',
                success: 'Truck successfully deactivated',
            },
            messageActivate: 'The truck is deactivated',
            locationTruck: 'Fill the yard location which is required',
            dropTrailer: 'Drop the trailer to deactivated truck',
            errorMessage: {
                fleetNumber: 'Fleet number must be unique among active trucks.'
            }
        },
        field: {
            id: {
                label: 'Id',
            },
            attachedTrailer: {
                label: 'Attached trailer',
            },
            fleetNumber: {
                label: 'Fleet number',
            },
            licensePlate: {
                label: 'License plate',
            },
            owner: {
                label: 'Owner',
            },
            contract: {
                label: 'Contract',
            },
            truckingCompany: {
                label: 'Owner company'
            },
            fuelCapacity: {
                label: 'Fuel capacity'
            },
            telematica: {
                label: 'Data Provider',
            },
            dataSource: {
                label: 'Data provider',
            },
            dataReference: {
                label: 'Key',
            },
            phone: {
                label: 'Phone',
            },
            location: {
                label: 'Yard location',
            },
            terminal: {
                label: 'Supported Terminals',
            },
            color: {
                label: 'Color'
            },
            type: {
                label: 'Type',
                value: {
                    dallessi: 'Dalessi',
                    TIP: 'TIP'
                }
            },
            registrationCountry: {
                label: 'Registration country'
            },
            brandType: {
                label: 'Brand type'
            },
            emailHaulier: {
                label: 'Email haulier'
            },
            service: {
                label: 'Service'
            },
            weight: {
                label: 'Weight'
            },
            registrationDate: {
                label: 'Registration date'
            },
            fixedEquipment: {
                label: 'Fixed equipment'
            },
            remarks: {
                label: 'Remarks'
            },
            entity: {
                label: 'Entity'
            },
            active: {
                label: 'Inactive / Active truck',
                overviewColumn: 'Active',
                options: {
                    all: 'All',
                    yes: 'Active',
                    no: 'Inactive',
                }
            },
            assignedUser: {
                label: 'Assigned to'
            },
            currentLocation: {
                label: 'Current Location'
            },
            currentActivity: {
                label: 'Current Activity'
            },
            manualEta: {
                label: 'Manual ETA'
            },
            weekendPause: {
                overviewColumn: 'Weekend pause',
            },
            mileage: {
                label: 'Mileage'
            },
            nextApk: {
                label: 'Next APK'
            },
            nextTacho: {
                label: 'Next Tacho'
            },
            permit: {
                label: 'Permit',
            },
            permitExpirationDate: {
                label: 'Permit expiration date'
            },
            waPermit: {
                label: 'WA permit'
            },
            wabco: {
                label: 'Wabco',
                options: {
                    all: 'All',
                    yes: 'Yes',
                    no: 'No',
                }
            },
            soldAt: {
                label: 'Sold at',
            }
        },
        create: {
            title: 'Add truck'
        },
    },
    tuv: {
        overview: {
            addButton: 'Add TUV',
        },
        field: {
            tuvExecutedDate: {
                label: 'TUV executed date'
            },
            tuvExpiryDate: {
                label: 'TUV expiry date'
            },
        }
    },
    driver: {
        overview: {
            title: 'Drivers truck list',
            editButton: 'Edit driver',
            addButton: 'Add driver',
            addRouteButton: 'Add break',
            popup:{
                info: 'Ends / starts in less than 14 days \n Remaining number of days: {{n}} \n Red - driver is going out \n Blue - driver is coming back',
            }
        },
        create: {
            title: 'Add driver',
        },
        edit: {
            title: 'Edit driver',
        },
        field: {
            header: {
                label: 'Driver'
            },
            id: {
                label: 'Id'
            },
            name: {
                label: 'Driver\'s name',
                placeholder: 'Driver\'s name',
            },
            firstName: {
                label: 'Driver\'s first name',
                placeholder: 'Driver\'s first name',
            },
            lastName: {
                label: 'Driver\'s last name',
                placeholder: 'Driver\'s last name',
            },
            phoneNumber: {
                label: 'Driver\'s phone',
                placeholder: 'Driver\'s phone number',
            },
            dataReference: {
                label: 'Key',
                placeholder: 'Key',
            },
            addDriver: {
                label: 'Add driver'
            },
            selectDriver: {
                label: 'Select existing driver'
            },
            truck: {
                label: 'Truck',
                licensePlate: {
                    label: 'Truck license plate'
                },
                fleetNumber: {
                    label: 'Truck fleet number'
                },
            },
            weekendPause: {
                label: 'Weekend pause',
                options: {
                    '24': '24h',
                    '45': '45h',
                    stopTruck: 'Stop truck',
                    home: 'Home'
                },
                placeholder: 'Unknown',
                shorterLabels: {
                    stopTruck: 'ST',
                    home: 'H'
                }
            },
        }
    },
    goodsDescription: {
        overview: {
            title: 'Goods description list',
        },
        create: {
            title: 'New goods description',
        },
        edit: {
            title: 'Edit goods description',
        },
        field: {
            id: {
                label: 'Id'
            },
            name: {
                label: 'Description',
                placeholder: 'Description',
            },
            usageCount: {
                label: 'Frequency of use',
                placeholder: 'Frequency of use',
            }
        }
    },
    service: {
        overview: {
            title: 'Service list for trucks and trailers',
            addButton: 'Add service',
            showMore: 'Show more',
            markAsDone: 'Mark as done',
        },
        create: {
            title: 'Create service',
        },
        edit: {
            title: 'Edit service {{id}}',
            chooseTruck: 'Choose truck',
            chooseTrailer: 'Choose trailer',
            errorMessage: 'Can\'t assign service to both truck and trailer.',
        },
        delete: {
            title: 'Delete service',
        },
        types: {
            repair: 'repair',
            service: 'service',
            maintenance: 'maintenance',
            other: 'other',
        },
        field: {
            id: {
                label: 'Id',
            },
            type: {
                label: 'Type'
            },
            location: {
                label: 'Location'
            },
            visitDate: {
                label: 'Date of visit'
            },
            visitTime: {
                label: 'Time of visit'
            },
            subject: {
                label: 'Subject'
            },
            estimatedDueTime: {
                label: 'Estimated due time'
            },
            explanation: {
                label: 'Rich text explanation'
            },
            truck: {
                label: 'Truck fleet number'
            },
            trailer: {
                label: 'Trailer fleet number'
            },
            status: {
                label: 'Status'
            },
            markedAsDone: {
                label: 'Marked as done'
            }
        }
    },
    route: {
        overview: {
            title: 'Breaks overview',
            addButton: 'Add break',
            editButton: 'Edit break',
            copyButton: 'Copy break',
        },
        create: {
            title: 'New break',
        },
        view: {
            title: 'Break view',
        },
        delete: {
            title: 'Delete break',
        },
        edit: {
            title: 'Edit break'
        },
        field: {
            name: {
                label: 'Name',
            },
            code: {
                label: 'Integration Code',
            },
            transporter: {
                label: 'Terminal Transporter',
            },
            fromTerminal: {
                label: 'Departure Terminal',
            },
            toTerminal: {
                label: 'Arrival Terminal',
            },
            invoiceWay: {
                label: 'Invoice Way',
            },
            deactivateModal: {
                description: 'Please enter the date from which this schedule and its departures should be deactivated.'
            },
            validFrom: {
                label: 'From'
            },
            validTo: {
                label: 'To'
            }
        },
        planning: {
            iconLabel: 'Break',
            in14DaysLabel: 'Upcoming in 14 days',
            after14DaysLabel: 'After 14 days'
        }
    },
    locationEditFreeForm: {
        label: 'Location',
        placeholder: 'Choose a location',
        showLocationText: 'Show location'
    },
    location: {
        field: {
            city: {
                label: 'City',
            },
            code: {
                label: 'Code',
            },
            companyName: {
                label: 'Company',
            },
            address: {
                label: 'Address',
            },
        },
    },
    truckingCompany: {
        overview: {
            title: 'Transport company overview',
            addButton: 'Add transport company',
            truckCount: 'Truck count',
        },
        create: {
            title: 'Create transport company',
        },
        edit: {
            title: 'Transport company - {{name}}',
            addPurchaseInvoiceButtonTooltip: 'Add Purchase Invoice',
        },
        trucks: {
            add: 'Add truck',
        },
        trailer: {
            add: 'Add trailer',
        },
        contract: {
            add: 'Add contract',
        },
        tab: {
            trucks: 'Trucks',
            trailers: 'Trailers',
            contracts: 'Contracts',
        },
        field: {
            name: {
                label: 'Name',
                placeholder: 'Fill in a name',
            },
            creditorNumber: {
                label: 'Creditor number',
                placeholder: 'Fill in a creditor number',
            },
            colorCompany: {
                label: 'Company color',
                placeholder: 'choose color',
            },
            emailAddress: {
                label: 'Email Address',
                placeholder: 'e-mail',
            },
            phone: {
                label: 'Telephone',
                placeholder: 'phone number',
            },
            location: {
                label: 'Country Code',
            },
            iban: {
                label: 'IBAN',
                placeholder: 'Fill in a IBAN number',
            },
            swiftBic: {
                label: 'SWIFT / BIC code',
                placeholder: '',
            },
            chamberOfCommerce: {
                label: 'Chamber of Commerce',
                placeholder: '',
            },
            vatCode: {
                label: 'VAT code',
                placeholder: '',
            },
            specialization: {
                label: 'Specialization',
            },
            paymentTerms: {
                label: 'Payment Terms (in days)',
            },
            blacklisted: {
                label: 'Blacklisted',
                options: {
                    all: 'All',
                    blacklisted: 'Yes',
                    notBlacklisted: 'Not',
                }
            },
            blacklistedReason: {
                label: 'Blacklisted reason',
            },
            contact: {
                label: 'Contact',
                placeholder: 'Select contact type',
                value: {
                    dayshift: 'Dayshift',
                    invoice: 'Invoice',
                    nightshift: 'Nightshift',
                    administration: 'Administration',
                    general: 'General',
                    operation: 'Operation'
                }
            },
            signedWageAgreement: {
                label: 'Signed min. wage agreement',
            },
            signedAgreementExpiryDate: {
                label: 'Signed agreement expiry date',
            },

            liabilityInsurance: {
                label: 'Liability and insurance',
            },
            liabilityInsuranceExpiryDate: {
                label: 'Liability insurance expiry date',
            },
            letterheadExpiryDate: {
                label: 'Letterhead expiry date'
            },
            euLicenseForGoodsTransportationExpiryDate: {
                label: 'EU license expiry date'
            },
            cmrInsurancePolicyExpiryDate: {
                label: 'CMR insurance policy expiry date'
            },
            licenses: {
                label: 'Licenses',
            },
            letterhead: {
                label: 'Letterhead',
            },
            eu_license_for_goods_transportation: {
                label: 'EU license for goods transportation',
            },
            cmr_insurance_policy: {
                label: 'CMR insurance policy',
            },
            licensesExpiryDate: {
                label: 'Licenses expiry date',
            },
            subcontractor: {
                label: 'Subcontractor',
            },
            priority: {
                label: 'Priority',
                instruction: 'Higher company priority will show it and it\'s assets higher in the lists',
            },
            owner: {
                label: 'Owner',
                options: {
                    all: 'All',
                    own: 'Own',
                    subcontractors: 'Contractors',

                }
            }
        },
    },
    district: {
        overview: {
            title: 'District',
        },
        field: {
            id: {
                label: 'Id',
            },
            name: {
                label: 'Name area',
            },
            area: {
                label: 'Polygon',
            },
        }
    },
};
