import { getCurrentUser } from 'helpers/currentUser';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Message, Header } from 'semantic-ui-react';
import styled from 'styled-components';
import { DATETIME_FORMAT, snakeToCamel } from 'helpers';
import { trim } from 'lodash';
import { HistoryStore } from 'store/History';
import { Model } from '@code-yellow/spider';

const List = styled.li`
    list-style: none;
    padding-bottom: 1.5rem;
    border-left: 4px solid black;
    position: relative;
    padding-left: 15px;
    padding-right: 15px;
    margin-left: 0px;
    &:last-child{
        border-left: 4px solid white;
        padding-bottom: 0;
    }
    &:before{
        content: '';
        width: 20px;
        height: 20px;
        border: 4px solid black;
        background: white;
        border-radius: 50%;
        position: absolute;
        left: -12px;
        top: 0px;
        font-size: 12px;
    }
`;

const HistoryItem = styled.div`
    top: -10px;
    font-size: 15px;
`;

const StyledHistory = styled.div`
    height: 100%;
`;

const UnorderedList = styled.ul`
    margin-top: 1rem;
    position: relative;
`;

const Name = styled.span`
    font-weight: bold;
`;
const Rest = styled.span`
    color: grey;
`;

const Values = styled.span`
    font-weight: bold;
`;

const Date = styled.span`
    color: grey;
    font-size: 14px;
    float: right;
`;

const StyledMessage = styled(Message)`
display: block!important;
`;

@observer
export default class HistoryFeed extends Component {
    static propTypes = {
        object: PropTypes.instanceOf(Model).isRequired,
        config: PropTypes.array.isRequired,
        history: PropTypes.instanceOf(HistoryStore).isRequired,
        valueParser: PropTypes.func.isRequired,
    };

    @observable currentUser = getCurrentUser();
    @observable flatHistory = [];

    componentDidMount() {
        this._arrangeHistory();
    }

    componentDidUpdate(prevProps) {
        if (this.props.history !== prevProps.history) {
            this._arrangeHistory();
        }
    }

    _arrangeHistory() {
        const { history } = this.props;
        if (history !== null) {
            this.flatHistory = this._getFilteredHistory(history);
        }
    }

    _getFilteredHistory(history) {
        const { object, config } = this.props;

        const parentModelName = this._getModelName();

        let flatHistory = [];
        let groupedHistory = history.groupedByDate();

        groupedHistory.forEach(item => item.changes.models.forEach(
            change => {
                config.forEach(conf => {
                    const modelName = (conf.model_name ? conf.model_name : parentModelName).toLowerCase();
                    let isParent = false;
                    let validModel = modelName === change.model.toLowerCase();
                    let validField = false;

                    if (conf.tracked_field) {
                        validField = conf.tracked_field === change.field;
                        isParent = true;
                    } else {
                        validField = conf.tracked_fields?.includes(change.field);
                    }

                    if (validModel && conf.id_only) {
                        validModel = change.oid === object?.id;
                    }

                    if (!validField || !validModel) {
                        return;
                    }

                    let before = this._getValue(change.before);
                    let after = this._getValue(change.after);

                    if (before === after) {
                        return;
                    }

                    if (isParent) {
                        flatHistory.push({
                            item: item,
                            change: change
                        });
                    } else {
                        const existing = flatHistory.find((element) => element.item.id === item.id && element.model === change.model && element.oid === change.oid && element.children);

                        if (existing) {
                            existing.children.push(change);
                        } else {
                            flatHistory.push({
                                model: change.model,
                                oid: change.oid,
                                item: item,
                                children: [change]
                            });
                        }
                    }
                });
            }
        ));

        return flatHistory;
    }

    _getValue(value) {
        const result = trim(value, '"');

        if (this._isValueEmpty(result)) {
            return null;
        }

        return result;
    }

    _isValueEmpty(value) {
        return value === '' || value === 'null';
    }

    _getModelName() {
        const { object } = this.props;
        return snakeToCamel(object.constructor.backendResourceName.replace(/\//g, '_'));
    }

    _renderHistoryItem(parentModelName, itemConfig, item, change) {
        const { valueParser } = this.props;

        let action = t('history.changed');
        let before = trim(change.before, '"');
        let after = trim(change.after, '"');

        let isNested = false;

        if (itemConfig.model_name) {
            isNested = true;
        }

        const modelName = isNested ? itemConfig.model_name.toLowerCase() : parentModelName;
        const translationKey = itemConfig.translation_key ? `${itemConfig.translation_key}:` : '';
        const showModel = isNested && item;

        let afterDetailed = after;
        let fieldName = t(`${translationKey}${modelName}.field.${snakeToCamel(change.field)}.label`);

        if (this._isValueEmpty(before)) {
            action = t('history.added');
        } else if (this._isValueEmpty(after)) {
            action = t('history.removed');
            afterDetailed = before;
        }

        if (valueParser) {
            before = valueParser(change.model, change.field, before, before);
            after = valueParser(change.model, change.field, after, after);
            afterDetailed = valueParser(change.model, change.field, afterDetailed, afterDetailed);
        }

        return (
            <>
                <HistoryItem data-test-history-item>
                    {item && <Name>{item.user.fullName} </Name>}
                    <Rest>{action} <b>{fieldName}</b> </Rest>
                    <>
                        {!itemConfig.hide_details  && itemConfig.show_as_title && (
                            <>
                                {action !== t('history.added') && <>
                                    <Rest>from </Rest>
                                    <Values>{before} </Values>
                                    <Rest>to </Rest>
                                </>}
                                <Values>{after} </Values>
                            </>
                        )}
                    </>
                    {showModel && <>
                        <Rest>for </Rest>
                        <Values>{change.model} {change.oid} </Values>
                    </>}
                    {item && <Date>{item.date.toFormat(DATETIME_FORMAT)}</Date>}
                </HistoryItem>
                {!itemConfig.show_as_title && (
                    <>
                        <Message>{afterDetailed}</Message>
                    </>
                )}
            </>
        )
    }

    _displayHistory = (historyItem, i) => {
        const { config } = this.props;

        let content = null;

        const parentModelName = this._getModelName();

        if (historyItem.children) {
            content = (
                <>
                    <HistoryItem data-test-history-item>
                        <Name>{historyItem.item.user.fullName} </Name>
                        <Rest>changed </Rest>
                        <Values>{historyItem.model} {historyItem.oid} </Values>
                        <Date>{historyItem.item.date.toFormat(DATETIME_FORMAT)}</Date>
                    </HistoryItem>
                    {historyItem.children.map((element) => {
                        const itemConfig = config.find(conf => conf.model_name === historyItem.model && conf.tracked_fields && conf.tracked_fields.includes(element.field));
                        return this._renderHistoryItem(parentModelName, itemConfig, null, element);
                    })}
                </>
            )
        } else {
            const itemConfig = config.find(conf => {
                const modelName = (conf.model_name ? conf.model_name : parentModelName).toLowerCase();
                return modelName === historyItem.change.model.toLowerCase() && conf.tracked_field === historyItem.change.field;
            });
            content = this._renderHistoryItem(parentModelName, itemConfig, historyItem.item, historyItem.change);
        }

        return (
            <React.Fragment key={i}>
                <List>
                    {content}
                </List>
            </React.Fragment>
        )
    }

    _renderCount() {
        if (this.flatHistory.length > 0) {
            return <span data-test-history-items-count>({this.flatHistory.length})</span>;
        }

        return null;
    }

    _renderContent() {
        if (this.flatHistory.length === 0) {
            return (
                <StyledMessage warning data-test-empty-history>
                    <p>{t('history.emptyBox.label')}</p>
                </StyledMessage>
            )
        }

        return (
            <StyledHistory data-test-history-feed>
                <UnorderedList>
                    {this.flatHistory.length > 0 && this.flatHistory.map(this._displayHistory)}
                </UnorderedList>
            </StyledHistory>
        )
    }


    render() {
        return (
            <>
                <Header>
                    <React.Fragment>
                        {t('history.title.label')}
                        {' '}
                        {this._renderCount()}
                    </React.Fragment>
                </Header>
                {this._renderContent()}
            </>
        );
    }
}
