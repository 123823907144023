import React, { Component } from 'react';
import { computed, observable, action } from 'mobx';
import { observer } from 'mobx-react';
import { Form, Button, Grid, Modal, Icon, Item, Checkbox } from 'semantic-ui-react';
import styled from 'styled-components';

import { EmailAttachment, EmailMessage as Email, IEntityEmailMixin } from '../store/EmailMessage';
import { EmailTemplate, EmailTemplateStore } from '../store/EmailTemplate';
import { Model, CancelButton, SaveButton, SendButton, RightDivider, showErrorNotification, showSaveNotification, TargetSelect, TargetTextInput, stripQueryParams, t, ItemButton } from '@code-yellow/spider';

import { sendEntityEmail } from '../services/MailService';
import { FullHeightModal } from './FullHeightModal';
import ComposeEmail from '../component/ComposeEmail';
import RenderedEmailTemplatePreview from './RenderedEmailTemplatePreview';

import { UserStore } from 'store/User';
import { ContactStore } from 'react-logistics-administration/src';
import { PdfViewer } from '../component/PdfViewer';
import { groupBy } from 'lodash';

const StyledComposeEmail = styled(ComposeEmail)`
    .DraftEditor-root {
        min-height: 10em;
    }
`;
const AttachmentsContainer = styled.div`
    margin: 12px 0;
    display: flex;
    flex-direction: column;

    > div {
        font-size: 13px;
        font-weight: 700;
    }

    > a {
        margin: 2px 4px !important;
        text-align: left !important;
    }
`
const BorderFormField = styled(Form.Field)<{ backgroundColor?: string }>`
    background-color: #e0e0e000;
    border: 1px solid #CCC;
    padding: 12px;
    border-radius: 5px;
    margin: 10px 0px;

    ${props => props.backgroundColor &&
        `background-color: ${props.backgroundColor};`
    };
`;
const ItemContent = styled(Item)`
    width: fit-content;
    display: flex;
    justifyContent: right;
`
const ItemWrapper = styled(Item)`
    margin: 0 0 0 12px;
    flex-grow: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
`
const ItemBox = styled(Item)`
    margin: 4px 0;
    align-items: center;
    width: 100%;
    display: flex;
`;


export interface EntityEmailModalProps {
    entity: Model & IEntityEmailMixin;
    entityLngPrefix?: string;
    getRecipients: () => Promise<string[]>;
    recipientsStoreParams: object;
    templateArguments: {
        template_tag: string;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        [key: string]: any;
    };
    trigger: (props: object) => JSX.Element;
    afterSave: () => void;
    afterSend: () => void;
    onOpen: () => void;
    onSave: () => Promise<void>;
    onClose: () => void;
    header: JSX.Element;
    content: JSX.Element;
    includeAttachments: boolean;
    inlinePreview: boolean;
}

@observer
export class EntityEmailModal extends Component<EntityEmailModalProps> {
    @observable isOpen = false;
    open = () => this.isOpen = true;
    close = () => {
        const { onClose } = this.props;
        this.isOpen = false;

        if (onClose != null) {
            onClose();
        }
    }

    @observable email = new Email({

    });

    @observable recipients = [];
    @observable renderedEmailTemplate: EmailTemplate | null = null;

    @computed get templateTag() {
        return this.props.templateArguments?.template_tag ?? '[Default]';
    }

    @observable selectedTemplate: EmailTemplate | null = null;

    @observable emailTemplateStore = new EmailTemplateStore({
        params: {
            '.tag': this.props.templateArguments?.template_tag,
        }
    });

    @observable contactStore = new ContactStore({
        params: {
            '.email_address:not': '',
        },
    });

    @observable userStore = new UserStore({
        params: {
            '.email:not:icontains': 'codeyellow.nl',
            'limit': '100',
        },
    });

    @observable selectedAttachment: EmailAttachment | null = null;

    onOpen = async () => {
        this.open();
        await this.loadTemplates();
        this.loadCachedRecipients();
        this.getAvailableAttachments();
    }

    loadTemplates = async () => {
        this.emailTemplateStore.params['.tag'] = this.props.templateArguments?.template_tag;
        await this.emailTemplateStore.fetch();
        this.selectedTemplate = this.emailTemplateStore.models[0];
    }

    loadCachedRecipients = () => {
        this.loadRecipients();

        this.email.wrapPendingRequestCount(
            this.userStore.fetch()
        );
    }

    loadRecipients = () => {
        const { getRecipients, recipientsStoreParams } = this.props;

        if (recipientsStoreParams != null) {
            this.contactStore.params = { ...this.contactStore.params, ...recipientsStoreParams };

            this.email.wrapPendingRequestCount(
                this.contactStore.fetch().then(async () => {
                    // If explicit getRecipients is given use it for the recipients list
                    // else fill recipients list with all contactstore values
                    if (getRecipients) {
                        getRecipients().then((result) => {
                            this.email.setInput('recipients', result);
                        });
                    } else {
                        this.email.setInput(
                            'sentTo',
                            this.contactStore.map(c => c.emailAddress)
                        );
                    }
                })
            );
        }
    }

    saveAndSend = () => {
        const { onSave } = this.props;

        if (onSave != null) {
            return onSave().then(this.send);
        } else {
            return this.send()
        }
    }

    send = async () => {
        const { entity, afterSend = () => undefined } = this.props;

        const res = await sendEntityEmail(
            entity,
            this.email.sentTo,
            this.email.subject,
            this.renderedEmailTemplate.content,
            this.selectedAttachments,
            this.renderedEmailTemplate.sender,
            this.templateTag
        );

        afterSend();

        return res;
    }

    getTrigger() {
        const { entity } = this.props;
        const trigger = this.props.trigger ?? ((triggerProps) => (
            <Button primary
                {...triggerProps}
                size="small"
                icon="mail"
                disabled={entity.isLoading}
            />
        ));

        return trigger({ onClick: this.onOpen, 'data-test-show-entity-email-modal-button': entity.id });
    }

    isSingleEntity() {
        const { entity } = this.props;

        return entity instanceof Model;
    }

    getEntities() {
        const { entity } = this.props;
        if (this.isSingleEntity()) {
            return [entity]
        } else {
            return entity;
        }
    }

    @observable attachments: EmailAttachment[] = [];
    @observable selectedAttachments: EmailAttachment[] = [];

    async getAvailableAttachments() {
        const { entity, includeAttachments } = this.props;

        if(!includeAttachments) {
            this.attachments = [];
            this.selectedAttachments = [];
            return Promise.resolve();
        }

        this.attachments = await entity.getAvailableAttachments();
        this.selectedAttachments = [...this.attachments];
    }

    @action
    onSelectAllAttachments = (group: string) => {
        const isGroupSelected = this.selectedAttachments.some(a => a.group === group);

        this.selectedAttachments = isGroupSelected
            ? this.selectedAttachments.filter(a => a.group !== group)
            : [...this.attachments.filter(a => a.group === group), ...this.selectedAttachments];
    }


    renderAttachments() {
        const { includeAttachments, inlinePreview, entityLngPrefix } = this.props;

        if (!includeAttachments) {
            return <></>;
        }

        let previewButton = (attachment) => (
            <Button icon data-test-preview-attachment={attachment.id}
                href={attachment.previewUrl} target='_blank'>
                <Icon name='eye' />
            </Button>
        )

        if(inlinePreview) {
            previewButton = (attachment) => (
                <Button icon data-test-preview-attachment={attachment.id}
                    onClick={() => {
                        if(this.selectedAttachment?.id === attachment.id) {
                            this.selectedAttachment = null;
                        }
                        else {
                            this.selectedAttachment = attachment;
                        }
                    }}>
                    <Icon name='eye' />
                </Button>
            )
        }

        const groups = groupBy(this.attachments, a => a.group);

        return (
            <AttachmentsContainer>
                <div>{t('communication:modal.sendEmail.attachments.label')}</div>
                <BorderFormField>
                    {Object.entries(groups).map(([group, attachments]) => <>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '10px 0' }}>
                            {group && entityLngPrefix && <div>{t(`${entityLngPrefix}.email.attachment.group.${group}`)}:</div>}
                            <ItemButton data-test-select-attachments-button compact
                                icon="checkmark"
                                onClick={()=>this.onSelectAllAttachments(group)}
                                content={'Select all'}
                            />
                        </div>

                        {attachments.map((attachment) => (
                            <ItemBox data-test-attachment key={attachment.id}>
                                <ItemWrapper>
                                    <Checkbox data-test-select-attachment
                                        style={{ paddingRight: '30px' }}
                                        checked={this.selectedAttachments.includes(attachment)}
                                        onChange={(e, data) => {
                                            const isAlreadySelected = this.selectedAttachments.includes(attachment);

                                            if(data.checked && !isAlreadySelected) {
                                                this.selectedAttachments.push(attachment);
                                            }
                                            else if(!data.checked && isAlreadySelected) {
                                                this.selectedAttachments = this.selectedAttachments.filter(a => a !== attachment);
                                            }
                                        }}
                                    />
                                    <Item style={{ flexGrow: 1 }}>{attachment.name}</Item>
                                    <ItemContent>
                                        {previewButton(attachment)}
                                        <Button icon
                                            href={attachment.downloadUrl}>
                                            <Icon name='download' />
                                        </Button>
                                    </ItemContent>
                                </ItemWrapper>
                            </ItemBox>
                        ))}
                    </>)}
                </BorderFormField>
            </AttachmentsContainer >
        );
    }

    render() {
        const { entity, templateArguments, header, content, onSave, afterSave = () => undefined, onOpen = () => undefined, inlinePreview } = this.props;

        const columns = inlinePreview && this.selectedAttachment ? 3 : 2;
        const size = inlinePreview ? 'fullscreen' : 'large';

        return (
            <FullHeightModal
                size={size}
                centered={false}
                open={this.isOpen}
                onClose={this.close}
                trigger={this.getTrigger()}
                onOpen={onOpen}
                data-test-entity-email-modal
            >

                {header ?? <Modal.Header>{t('communication:modal.sendEmail.header')}</Modal.Header>}
                <Modal.Content scrolling={true}>
                    <Grid columns={columns}>
                        <Grid.Column>
                            <Form>
                                <TargetSelect
                                    name='template'
                                    search remote
                                    searchKey=".name:icontains"
                                    value={this.selectedTemplate}
                                    label={t('communication:modal.sendEmail.template.label')}
                                    options={
                                        this.emailTemplateStore.map(item => ({
                                            value: item,
                                            text: item.name,
                                        }))
                                    }
                                    afterChange={(value) => {
                                        this.selectedTemplate = value;
                                    }}
                                    disabled={!templateArguments?.canChange}
                                />
                                <TargetTextInput
                                    disabled
                                    label={t('communication:emailTemplate.field.senderEmail.label')}
                                    target={this.selectedTemplate}
                                    name='senderEmail'
                                />
                                {content ?? null}
                                <StyledComposeEmail wysiwyg
                                    model={this.email}
                                    contactStore={this.contactStore}
                                    userStore={this.userStore}
                                />
                                {this.renderAttachments()}
                            </Form>
                        </Grid.Column>
                        <Grid.Column>
                            <RenderedEmailTemplatePreview
                                entity={entity}
                                email={this.email}
                                afterRenderTemplate={renderedEmailTemplate => {
                                    this.renderedEmailTemplate = renderedEmailTemplate;
                                }}
                                templateArguments={{ ...templateArguments, template_tag: this.templateTag }}
                            />
                        </Grid.Column>
                        {
                            inlinePreview && this.selectedAttachment && (
                                <Grid.Column>
                                    <PdfViewer url={stripQueryParams(this.selectedAttachment.previewUrl)} data-test-pdf-viewer data-test-pdf-viewer-attachment={this.selectedAttachment.id} />
                                </Grid.Column>
                            )
                        }
                    </Grid>
                </Modal.Content>
                <Modal.Actions>
                    <RightDivider />
                    <CancelButton data-test-cancel-button
                        onClick={this.close}
                    />
                    {onSave ?
                        <SaveButton primary data-test-save-button
                            shortcut="ctrl+enter"
                            loading={entity.isLoading}
                            onClick={() => {
                                onSave()
                                    .then(afterSave)
                                    .then(showSaveNotification)
                                    .then(this.close)
                                    .catch((err) => {
                                        // [TODO] Add proper error notification
                                        console.error(err);
                                        showErrorNotification('Saving failed');
                                    });
                            }}
                        /> : null}

                    <SendButton primary data-test-save-and-send-button
                        content={t('communication:modal.send')}
                        shortcut="ctrl+shift+enter"
                        loading={entity.isLoading}
                        onClick={() => this.saveAndSend()
                            .then(afterSave)
                            .then(showSaveNotification)
                            .then(this.close)
                            .catch((err) => {
                                // [TODO] Add proper error notification
                                console.error(err);
                                showErrorNotification('Saving failed');
                                // showErrorNotifications(err.response.data.errors);
                            })
                        }
                    />
                </Modal.Actions>
            </FullHeightModal>
        );
    }
}
