export default {
    nav: {
        finance: {
            label: 'Finance',
            invoice: 'Invoices',
            contract: 'Contracts',
            ledger: 'Ledgers',
            invoiceEntry: 'Create invoice',
        },
    },
    contract: {
        create: {
            title: 'Add contract',
        },
        edit: {
            title: 'Edit contract {{name}}'
        },
        overview: {
            title: 'Contracts',
            addButton: 'Add contract',
        },
        tab: {
            info: 'Basic information'
        },
        field: {
            name: {
                label: 'Name'
            },
            customer: {
                label: 'Customer'
            },
            startDate: {
                label: 'Start date'
            },
            endDate: {
                label: 'End date'
            }
        }
    },
    ledger: {
        overview: {
            title: 'Ledger overview',
        },
        create: {
            title: 'New Ledger'
        },
        edit: {
            title: 'Edit Ledger',
        },
        field: {
            id: {
                label: 'ID',
            },
            entity: {
                label: 'Entity',
            },
            number: {
                label: 'Number',
            },
            description: {
                label: 'Description',
            },
            vatCode: {
                label: 'Vat Code',
            },
            vatName: {
                label: 'Vat Name',
            },
            vatPercentage: {
                label: 'Vat Percentage',
                noVat: 'No Vat',
            },
        },
    },
    invoice: {
        create: {
            title: 'New invoice'
        },
        edit: {
            noContract: 'No contract',
            button: {
                addCustomLine: 'Add custom line',
                saveAndView: 'Save & View',
                view: 'View',
                revertToDraft: 'Revert to draft',
            },
            subtotal: 'Subtotal',
            vat: 'BTW',
            total: 'Total',
            creditBalance: 'Credit balance',
            billableCustomers: 'Billable customers',
            otherCurrency: 'Other currency',
            customerInvoice: 'Customer invoice {{number}}',
            weekNumber: 'W nr.',
            dossierNumber: 'Dossier nr.',
            numberOfTrips: '# trips',
            dossierGroupAccordionTitle: 'Dossier remarks, documents',
            selfBillGroupAccordionTitle: 'Self-bill remarks, documents',
            lineGroupHeader: {
                trip: {
                    from: 'From',
                    to: 'To',
                    pod: 'POD',
                    tripRemarks: 'Trip remarks',
                    total: 'Total',
                },
                selfBillItem: {
                    costCentreNumber: 'Cost centre number',
                    distributionItem: 'Distribution item',
                    type: 'Type',
                    total: 'Total',
                },
            },
        },
        overview: {
            title: 'Invoices',
            availableInvoices: 'Available invoices',
            noInvoicableCustomers: 'There are no contracted customers with invoicable activities.',
            downloadButton: 'Download Twinfield export',
        },
        field: {
            id: {
                label: 'ID',
            },
            contract: {
                label: 'Contract'
            },
            issueDate: {
                label: 'Invoice date',
                week: 'Week',
                period: 'Period',
            },
            number: {
                label: 'Number',
                short: 'No.',
                long: 'Invoice number',
            },
            reference: {
                label: 'Invoice reference'
            },
            remarks: {
                label: 'Remarks',
                labelDetailed: 'Internal remarks (will not be send to the customer)'
            },
            totalAmount: {
                label: 'Total amount',
                short: 'Total',
            },
            dueDate: {
                label: 'Due date'
            },
            customer: {
                label: 'Customer'
            },
            entity: {
                label: 'Invoice entity',
            },
            debtorNumber: {
                label: 'Debtor number',
            },
            language: {
                label: 'Invoice language',
                option: {
                    nl: 'Dutch',
                    en: 'English',
                    de: 'German',
                }
            },
            vatNumber: {
                label: 'BTW number'
            },
            vatCode: {
                label: 'BTW code'
            },
            vatName: {
                label: 'BTW name'
            },
            dossier: {
                label: 'Dossier',
                dossierNumber: {
                    short: 'nr.',
                    label: 'Dossier number',
                },
                invoiceReference: {
                    short: 'Ref.',
                    label: 'Dossier reference',
                },
                documents: {
                    label: 'Documents'
                },
                remarks: {
                    label: 'Internal dossier remarks'
                }
            },
            currency: {
                label: 'Currency'
            },
            subtotalAmount: {
                label: 'Subtotal'
            },
            dossiers: {
                label: 'Dossiers'
            },
            period: {
                label: 'Period'
            },
            week: {
                label: 'Week'
            },
            documents: {
                label: 'Documents'
            },
            selfBill: {
                remarks: {
                    label: 'Self-bill remarks'
                },
                documents: {
                    label: 'Documents'
                },
                reference: {
                    label: 'Self-bill reference'
                },
            },
            selfBills: {
                label: 'Self-bills'
            },
            status: {
                label: 'Status',
                option: {
                    drafted: {
                        label: 'Drafted',
                    },
                    sent: {
                        label: 'Sent',
                    },
                    exported: {
                        label: 'Exported',
                    },
                    all: {
                        label: 'All',
                    }
                },
            },
        },
        button: {
            saveAndSend: 'Save & Send',
            infoToggle: {
                loadedInfo: '"From To"',
                customInfo: 'Description'
            },
            twinfieldExport: {
                label: 'Download Twinfield export',
                invalidStatus: 'Only invoices with status "Sent" and "Exported" can be exported to Twinfield.',
                noInvoicesSelected: 'No invoices selected.',
                modal: {
                    header: 'Are you sure you want to export the selected invoices to Twinfield?',
                    reexportContent: 'Some of the selected invoices have already been exported to Twinfield. Are you sure you want to re-export them?',
                    invalidStatusContent: 'Some of the invoices have an invalid status. Only invoices with status "Sent" and "Exported" from the selection will be exported!',
                    cancel: 'Cancel',
                    export: 'Export',
                },
            },
        },
        email: {
            attachment: {
                group: {
                    document: 'Documents',
                    pod: 'PODs',
                }
            }
        }
    },
    invoiceLine: {
        field: {
            description: {
                label: 'Description'
            },
            amount: {
                label: 'Amount'
            },
            vat: {
                label: 'VAT'
            },
            totalAmount: {
                label: 'Total amount'
            }
        }
    },
    selector: {
        title: 'Selector',
        search: {
            placeholder: 'Search...',
        },
        invoice: {
            selfBilling: 'Self-billing',
        },
        selfBillItem: {
            weekNumber: 'W',
            reference: 'Reference',
            costCentreNumber: 'Cost centre number',
        }
    },
    selfBill: {
        modal: {
            linkToSelfBill: {
                header: {
                    new: 'Link self-bill',
                    linked: 'Linked self-bill',
                    dossiers: 'Dossiers {{count}}',
                    invoices: 'Invoices {{count}}',
                },
                actions: {
                    button: {
                        deleteItem: {
                            tooltip: 'Cannot delete already invoiced self-bill item',
                        },
                        linkAndGenerateInvoiceRequests: 'Link self-bill & generate invoice requests',
                        saveAndClose: 'Save & close',
                        cancel: 'Cancel',
                        unlink: {
                            label: 'Undo link and cancel invoices',
                            header: 'Undo link and cancel invoices',
                            content: 'Are you sure you want to unlink the self-bill and cancel the invoices?',
                        },
                    }
                }
            }
        },
        field: {
            period: {
                label: 'Period'
            },
            reference: {
                label: 'Reference'
            },
            remarks: {
                label: 'Remarks',
                placeholder: 'Add remark'
            },
            documents: {
                label: 'Documents'
            },
            items: {
                error: 'Self-bill requires at least one item.',
            }
        },
        button: {
            uploadDocument: 'Upload document',
            addItem: 'Add item',
        }
    },
    selfBillItem: {
        button: {
            add: 'Add item'
        },
        field: {
            costCentreNumber: {
                label: 'Cost centre number'
            },
            distributionItem: {
                label: 'Distribution item'
            },
            type: {
                label: 'Type'
            },
            amount: {
                label: 'Amount EURO'
            },
        },
    }

};
